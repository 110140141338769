import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=9d65ec4a&scoped=true&"
import script from "./TheHeader.vue?vue&type=script&lang=js&"
export * from "./TheHeader.vue?vue&type=script&lang=js&"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=9d65ec4a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d65ec4a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeaderNav: require('/home/forge/www.champagne-booking.com/components/TheHeaderNav.vue').default,TheHeaderUserMenu: require('/home/forge/www.champagne-booking.com/components/TheHeaderUserMenu.vue').default,SearchModal: require('/home/forge/www.champagne-booking.com/components/SearchModal.vue').default,LanguageSwitcher: require('/home/forge/www.champagne-booking.com/components/LanguageSwitcher.vue').default})
