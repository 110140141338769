import _ from 'lodash';

const actions = {
  initialize({ commit }) {
    let recentlyViewed = this.$cookies.get('recently-viewed');

    if (!recentlyViewed) {
      return;
    }

    commit('setTypes', JSON.parse(JSON.stringify(recentlyViewed)));
  },
  addToRecentlyViewed({ commit, getters, state }, { type, id }) {
    let wasViewed = getters.wasViewed(type, id);

    if (wasViewed) {
      commit('updateViewed', { type, id });
      return;
    }

    if (state.types[type].length > 5) {
      commit('removeFirstItem', type);
    }

    commit('addViewed', { type, id });

    this.$cookies.set('recently-viewed', JSON.stringify(state.types));
  },
  async fetchRecentlyViewed({ getters }, { type, id }) {
    const ids = getters
      .getViewedByTime(type)
      .filter((item) => item.id !== id)
      .map((item) => item.id);

    const params = new URLSearchParams();
    params.append('id', `in:${ids.join(',')}`);

    let { data } = await this.$api.$get(`api/v1/${type}?${params}`);

    return _.sortBy(
      data,
      (activity) => getters.getViewed(type, activity.id).timestamp
    ).reverse();
  },
};

export default actions;
