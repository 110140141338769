import _ from 'lodash';

const getters = {
  getViewed: (state) => (type, id) =>
    _.find(state.types[type], (viewed) => Number(viewed.id) === Number(id)),
  wasViewed: (_, getters) => (type, id) => !!getters.getViewed(type, id),
  getViewedByTime: (state) => (type) =>
    _.orderBy(state.types[type], ['timestamp'], ['desc']),
  getViewedActivities: (_, getters) => getters.getViewedByTime('activities'),
  countViewed: (state) => (type) => state.types[type].length,
  countViewedActivities: (_, getters) => getters.countViewed('activities'),
};

export default getters;
