
import layout from '@/mixins/layout';

export default {
  mixins: [layout],
  head() {
    return {
      bodyAttrs: {
        class: 'layout-empty',
      },
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
};
