import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment-timezone';
import pluralize from 'pluralize';

export default function ({ i18n }, inject) {
  pluralize.addSingularRule(/volwassenen$/i, 'volwassene');
  pluralize.addPluralRule(/volwassenen$/i, 'volwassenen');
  pluralize.addSingularRule(/volwassene$/i, 'volwassene');
  pluralize.addPluralRule(/volwassene$/i, 'volwassenen');
  pluralize.addSingularRule(/kinderen$/i, 'kind');
  pluralize.addPluralRule(/kinderen$/i, 'kinderen');
  pluralize.addSingularRule(/kind$/i, 'kind');
  pluralize.addPluralRule(/kind$/i, 'kinderen');

  let locale = {
    nl: 'nl',
    fr: 'fr',
    en: 'en',
  }[i18n.locale];

  if (typeof locale === 'undefined') {
    locale = 'en';
  }
  
  let instance = moment().locale(locale);

  inject('moment', instance);
}
