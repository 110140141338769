import Vue from 'vue';

Vue.directive('click-outside', {
  bind(el, _, vnode) {
    el.clickOutside = function (e) {
      if (!el.contains(e.target)) {
        vnode.context.showDropdown = false;
      }
    };

    document.addEventListener('click', el.clickOutside);
  },
  unbind(el) {
    document.removeEventListener('click', el.clickOutside);
  },
});
