
export default {
  name: 'SearchModal',
  data() {
    return {
      query: '',
      timeout: null,
      results: this.cleanResults(),
      type: '',
      resource: null,
      isLoading: false,
      types: {
        maisons: 'maison',
        activities: 'activity',
        accommodations: 'accommodation',
        rooms: 'room',
        pages: 'page',
        posts: 'post',
      },
    };
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
  watch: {
    async query(query) {
      clearTimeout(this.timeout);

      this.isLoading = true;
      this.type = '';
      this.resource = null;

      this.timeout = setTimeout(async () => {
        if (query.length > 2) {
          const { data } = await this.$api.$get(`/api/v1/search?q=${query}`);

          this.results = data;
        } else {
          this.results = this.cleanResults();
        }

        this.isLoading = false;
      }, 400);
    },
    $route() {
      this.clear();
      this.$refs.modal?.hide();
      this.$refs.dropdown?.hide();
    },
    results: {
      deep: false,
      handler() {
        if (!this.hasResults) {
          return;
        }

        let firstResource = null;
        let firstType = '';

        for (let type in this.results) {
          for (let resource of this.results[type]) {
            firstType = this.types[type];
            firstResource = resource;
            break;
          }

          if (!!firstResource) {
            break;
          }
        }

        for (let type in this.results) {
          for (let resource of this.results[type]) {
            if (!!resource.title) {
              resource.highlight = this.highlight(resource.title, this.query);
            }

            if (!!resource.name) {
              resource.highlight = this.highlight(resource.name, this.query);
            }
          }
        }

        this.resource = firstResource;
        this.type = firstType;
      },
    },
  },
  methods: {
    cleanResults() {
      return {
        maisons: [],
        activities: [],
        accommodations: [],
        rooms: [],
        pages: [],
        posts: [],
      };
    },
    setHover({ resource, type }) {
      this.resource = resource;
      this.type = type;
    },
    clear() {
      this.query = '';
      this.type = '';
      this.resource = null;
      this.results = this.cleanResults();
    },
    highlight(string, highlight) {
      let index = string.toLowerCase().indexOf(highlight.toLowerCase());

      if (index < 0) {
        return string;
      }

      return (
        string.substring(0, index) +
        '<b>' +
        string.substring(index, index + highlight.length) +
        '</b>' +
        string.substring(index + highlight.length)
      );
    },
  },
  computed: {
    hasMaisons() {
      return this.results.maisons && this.results.maisons.length > 0;
    },
    hasActivities() {
      return this.results.activities && this.results.activities.length > 0;
    },
    hasAccommodations() {
      return (
        this.results.accommodations && this.results.accommodations.length > 0
      );
    },
    hasRooms() {
      return this.results.rooms && this.results.rooms.length > 0;
    },
    hasPages() {
      return this.results.pages && this.results.pages.length > 0;
    },
    hasPosts() {
      return this.results.posts && this.results.posts.length > 0;
    },
    hasResults() {
      return (
        this.hasMaisons ||
        this.hasActivities ||
        this.hasAccommodations ||
        this.hasRooms ||
        this.hasPages ||
        this.hasPosts
      );
    },
    hasSearchQuery() {
      return this.query.length > 2;
    },
    showSearchPanel() {
      return (!this.isLoading && this.hasSearchQuery) || this.hasSearchQuery;
    },
  },
};
