
export default {
  name: 'SearchModalSidebar',
  props: {
    resource: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  computed: {
    isMaison() {
      return this.type === 'maison';
    },
    isActivity() {
      return this.type === 'activity';
    },
    isAccommodation() {
      return this.type === 'accommodation';
    },
    isRoom() {
      return this.type === 'room';
    },
    isPage() {
      return this.type === 'page';
    },
    isPost() {
      return this.type === 'post';
    },
  },
};
