
export default {
  name: 'LanguageFlag',
  props: {
    code: {
      required: true,
    },
    size: {
      default: 'l',
      type: String,
      validator: (value) => ['s', 'm', 'l'].includes(value),
    },
  },
  computed: {
    languageCode() {
      if (this.code === undefined) {
        return 'GB-UKM';
      }

      let code = this.code.toUpperCase();

      if (['UK', 'GB'].includes(code)) {
        return 'GB-UKM';
      }

      if (!this.$iso.is3166_2(code)) {
        return 'US';
      }

      return code;
    },
  },
};
