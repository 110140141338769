import _ from 'lodash';

const getters = {
  getFavorite: (state) => (type, id) =>
    _.find(state.types[type], (favoredId) => Number(favoredId) === Number(id)),
  isFavorite: (_, getters) => (type, id) => !!getters.getFavorite(type, id),
  countFavorites: (state) =>
    _.sumBy(Object.values(state.types), function (type) {
      return type.length;
    }),
};

export default getters;
