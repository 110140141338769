import Vue from 'vue';

function trailingSlash(path) {
  const parts = path.split('?');

  let url = parts[0];

  if (url.endsWith('/')) {
    return path;
  }

  url += '/';

  if (typeof parts[1] !== 'undefined' && parts[1]) {
    url += '?' + parts[1];
  }

  return url;
}

export default (_, inject) => {
  Vue.prototype.forceTrailingSlash = trailingSlash;
};
