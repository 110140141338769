
export default {
  name: 'SearchModalSidebarMaison',
  props: {
    maison: {
      type: Object,
    },
  },
  computed: {
    activities() {
      return [...this.maison.activities].splice(0, 4);
    },
  },
};
