import { render, staticRenderFns } from "./SearchModalActivity.vue?vue&type=template&id=1e978059&scoped=true&"
import script from "./SearchModalActivity.vue?vue&type=script&lang=js&"
export * from "./SearchModalActivity.vue?vue&type=script&lang=js&"
import style0 from "./SearchModalActivity.vue?vue&type=style&index=0&id=1e978059&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e978059",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppPrice: require('/home/forge/www.champagne-booking.com/components/AppPrice.vue').default})
