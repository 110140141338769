const layout = {
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
  watch: {
    '$auth.loggedIn'(isLoggedIn) {
      if (isLoggedIn) {
        if (!this.$nuxt.$route.name.includes('signup')) {
          this.$toast.success(this.$t('toasts.signed-in'));
        }
      } else {
        this.$toast.warning(this.$t('toasts.signed-out'));
      }
    },
  },
};

export default layout;
