import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faPaperPlane as freeFasFaPaperPlane,
  faChevronLeft as freeFasFaChevronLeft,
  faChevronRight as freeFasFaChevronRight,
  faUser as freeFasFaUser,
  faStar as freeFasFaStar,
  faStarHalfStroke as freeFasFaStarHalfStroke,
  faEnvelope as freeFasFaEnvelope,
  faAngleRight as freeFasFaAngleRight,
  faAnglesLeft as freeFasFaAnglesLeft,
  faCheck as freeFasFaCheck,
  faHeart as freeFasFaHeart,
  faPlus as freeFasFaPlus,
  faMinus as freeFasFaMinus,
  faXmark as freeFasFaXmark,
  faArrowRight as freeFasFaArrowRight,
  faArrowRightLong as freeFasFaArrowRightLong,
  faArrowUpRightFromSquare as freeFasFaArrowUpRightFromSquare,
  faRightFromBracket as freeFasFaRightFromBracket,
  faBars as freeFasFaBars,
  faThumbsUp as freeFasFaThumbsUp,
  faCheckSquare as freeFasFaCheckSquare,
  faInfo as freeFasFaInfo,
  faMagnifyingGlass as freeFasFaMagnifyingGlass,
  faMapMarkerAlt as freeFasFaMapMarkerAlt,
  faSquare as freeFasFaSquare,
  faUsers as freeFasFaUsers,
  faBed as freeFasFaBed,
  faClock as freeFasFaClock,
  faPencil as freeFasFaPencil,
  faBuilding as freeFasFaBuilding,
  faHotel as freeFasFaHotel,
  faHashtag as freeFasFaHashtag,
  faChartLine as freeFasFaChartLine,
  faCalendar as freeFasFaCalendar,
  faLocationDot as freeFasFaLocationDot,
  faMessage as freeFasFaMessage
} from '@fortawesome/free-solid-svg-icons'

import {
  faStar as freeFarFaStar,
  faHeart as freeFarFaHeart,
  faClock as freeFarFaClock
} from '@fortawesome/free-regular-svg-icons'

import {
  faTwitter as freeFabFaTwitter,
  faFacebookF as freeFabFaFacebookF,
  faWhatsapp as freeFabFaWhatsapp
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFasFaPaperPlane,
  freeFasFaChevronLeft,
  freeFasFaChevronRight,
  freeFasFaUser,
  freeFasFaStar,
  freeFasFaStarHalfStroke,
  freeFasFaEnvelope,
  freeFasFaAngleRight,
  freeFasFaAnglesLeft,
  freeFasFaCheck,
  freeFasFaHeart,
  freeFasFaPlus,
  freeFasFaMinus,
  freeFasFaXmark,
  freeFasFaArrowRight,
  freeFasFaArrowRightLong,
  freeFasFaArrowUpRightFromSquare,
  freeFasFaRightFromBracket,
  freeFasFaBars,
  freeFasFaThumbsUp,
  freeFasFaCheckSquare,
  freeFasFaInfo,
  freeFasFaMagnifyingGlass,
  freeFasFaMapMarkerAlt,
  freeFasFaSquare,
  freeFasFaUsers,
  freeFasFaBed,
  freeFasFaClock,
  freeFasFaPencil,
  freeFasFaBuilding,
  freeFasFaHotel,
  freeFasFaHashtag,
  freeFasFaChartLine,
  freeFasFaCalendar,
  freeFasFaLocationDot,
  freeFasFaMessage,
  freeFarFaStar,
  freeFarFaHeart,
  freeFarFaClock,
  freeFabFaTwitter,
  freeFabFaFacebookF,
  freeFabFaWhatsapp
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
