export default async function ({ redirect, app, route }) {
  let auth = app.$auth;

  if (!auth.loggedIn || !auth.user || auth.user.is_verified) {
    return;
  }

  await auth.fetchUser();

  if (auth.user.is_verified) {
    return;
  }

  let verificationPath = app.localePath({ name: 'verification' }) + '/';
  if (verificationPath !== route.path) {
    redirect(verificationPath);
  }
}
