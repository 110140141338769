import _ from 'lodash';

const mutations = {
  setChats(state, chats) {
    state.chats = chats;
  },
  addChat(state, chat) {
    state.chats.push(chat);
  },
  addMessageToChat(state, { chatId, message }) {
    let chat = _.find(state.chats, { id: chatId });

    chat.messages.push(message);
  },
};

export default mutations;
