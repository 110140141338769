const actions = {
  async initialize({ commit }) {
    const { data: settings } = await this.$api.$get('/api/v1/initialization');

    commit('setSettings', settings);
  },

  async fetchSettings({ commit }) {
    try {
      const { data: settings } = await this.$api.$get('/api/v1/initialization');
      commit('setSettings', settings);
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    }
  },
};

export default actions;
