
export default {
  name: 'TheFooterNavItem',
  props: [
    'href',
    'navLinkClass',
  ],
  computed: {
    isExternal() {
      let href = this.href;

      return href.startsWith('https://') || href.startsWith('http://');
    },
  },
};
