
export default {
  name: 'AppDropdown',
  props: {
    label: {
      type: String,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    alignEnd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdown: null,
    };
  },
  directives: {
    clickOutside: {
      bind(el, _, vnode) {
        el.clickOutside = function (e) {
          if (!el.contains(e.target)) {
            vnode.context?.hide();
          }
        };

        document.addEventListener('click', el.clickOutside);
      },
      unbind(el) {
        document.removeEventListener('click', el.clickOutside);
      },
    },
  },
  methods: {
    toggle() {
      this.dropdown?.toggle();
    },
    show() {
      this.dropdown?.show();
    },
    hide() {
      this.dropdown?.hide();
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.dropdown = new this.$bootstrap.Dropdown(this.$refs['dropdown']);
    });
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.hide();
      },
    },
  },
};
