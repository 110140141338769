
export default {
  name: 'TheHeaderNavItem',
  props: {
    to: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    subitems: {
      type: Array,
      required: false,
    },
    element: {
      type: String,
      default: 'li',
    },
  },
  computed: {
    hasSubitems() {
      return this.subitems && this.subitems.length > 0;
    },
    formattedSubitems() {
      return this.subitems.map((item) => {
        let component;

        switch (item.type) {
          case 'label':
            component = 'TheHeaderSubmenuLabel';
            break;
          case 'button':
            component = 'TheHeaderSubmenuButton';
            break;
          default:
            component = 'TheHeaderSubmenuItem';
            break;
        }

        return {
          ...item,
          component,
        };
      });
    },
  },
};
