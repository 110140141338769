
export default {
  name: 'SearchModalActivity',
  props: {
    activity: {
      type: Object,
    },
    hideExcerpt: {
      type: Boolean,
      default: false,
    },
    withBottomPrice: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    excerpt() {
      let description = this.activity.short_description;

      return description.length > 60
        ? description
            .replace(/<[^>]*>?/gm, '')
            .substr(0, 60)
            .concat('...')
        : description.replace(/<[^>]*>?/gm, '');
    },
    title() {
      return this.activity.highlight ?? this.activity.title;
    },
  },
};
