
export default {
  name: 'SearchModalRoom',
  props: {
    room: {
      type: Object,
    },
  },
  computed: {
    href() {
      return this.localePath({
        name: 'accommodations-accommodation',
        params: { ...this.room.slugs },
      });
    },
  },
};
