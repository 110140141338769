import Vue from 'vue';
import Formulate from '@braid/vue-formulate';
import { en, fr, nl } from '@braid/vue-formulate-i18n';

Vue.use(Formulate, {
  plugins: [nl, en, fr],
  classes: {
    outer: (context) => {
      if (context.classification === 'box') {
        if (context.type === 'radio' || context.type === 'checkbox') {
          return 'bg-transparent border-0';
        }
      }

      if (context.type === 'submit') {
        return 'hey he';
      }

      return 'mb-4';
    },
    element: ({ type }) => {
      if (type === 'radio' || type === 'checkbox') {
        return 'element-class';
      }
    },
    wrapper: ({ classification, type }) => {
      if (
        (classification === 'box' && type === 'radio') ||
        type === 'checkbox'
      ) {
        return 'd-flex';
      }
    },
    input: (context) => {
      if (context.type === 'submit') {
        return 'btn btn-gold';
      }

      if (context.type === 'select') {
        return 'form-select';
      }

      if (context.classification === 'box') {
        if (context.type === 'radio' || context.type === 'checkbox') {
          return 'form-check-input';
        }
      }

      if (context.classification === 'group') {
        return '';
      }

      return 'form-control';
    },
    label: ({ classification, type, validation, attrs }) => {
      let classes = '';

      if (classification === 'group') {
        classes = 'form-label d-block';
      } else if (classification === 'box') {
        classes = 'ms-2';
      } else if (type === 'radio' || type === 'checkbox') {
        classes = 'form-label d-block';
      } else {
        classes = 'form-label mb-2';
      }

      if (
        (typeof validation === 'string' && validation?.includes('required')) ||
        Object.keys(attrs).includes('required')
      ) {
        classes += ' form-label--required';
      }

      return classes;
    },
    errors: 'errors-list m-1',
    error: 'text-danger fs-8',
    help: 'form-text',
  },
  library: {
    'v-select': {
      classification: 'options',
      component: 'FormulateVSelect',
    },
    'country-select': {
      classification: 'options',
      component: 'FormulateCountrySelect',
    },
    phone: {
      classification: 'text',
      component: 'FormulatePhoneInput',
    },
    'complete-date-picker': {
      classification: 'date',
      component: 'FormulateCompleteDatePicker',
    },
  },
  rules: {
    valid: ({ value }, isValid) => {
      if (!value) {
        return true;
      }

      return isValid === 'false' ? false : true;
    },
  },
});
