
export default {
  name: 'TheHeaderUserMenu',
  computed: {
    items() {
      if (this.$auth.loggedIn) {
        let items = [];

        if (this.$auth.user.is_admin) {
          items.push({
            is_external: true,
            path: `${process.env.API_URL}/admin`,
            label: 'general.admin-panel',
          });
        }

        if (this.$auth.user.is_manager) {
          items.push({
            path: 'dashboard',
            label: 'general.dashboard',
          });
        }

        items.push(
          { path: 'account', label: 'general.account' },
          {
            label: 'general.signout',
            path: '#',
            onClick: this.signoutHandler,
          }
        );

        return items;
      }

      return [
        { path: 'signin', label: 'general.signin' },
        { path: 'signup', label: 'general.signup' },
      ];
    },
    subitems() {
      let subitems = [
        { type: 'label', label: this.$t('general.account-label') },
      ];

      subitems.push(
        ...this.items.map((item) => ({
          is_external: item.is_external,
          href:
            item.is_external || !!item.onClick
              ? item.path
              : this.localePath(item.path) ?? '#',
          label: this.$t(item.label),
          onClick: item.onClick,
        }))
      );

      return subitems;
    },
  },
  methods: {
    async signoutHandler(e) {
      e.preventDefault();

      await this.$auth.logout();
    },
  },
};
