import _ from 'lodash';

const mutations = {
  setTypes(state, types) {
    state.types = { ...state.types, ...types };
  },
  addFavorite(state, { type, id }) {
    state.types[type].push(id);
  },
  removeFavorite(state, { type, id }) {
    let index = _.findIndex(state.types[type], (item) => {
      return item == id;
    });

    state.types[type]?.splice(index, 1);
  },
};

export default mutations;
