import _ from 'lodash';

const mutations = {
  setTypes(state, types) {
    state.types = { ...state.types, ...types };
  },
  removeFirstItem(state, type) {
    let items = state.types[type];

    if (!items || items.length < 1) {
      return;
    }

    items = _.chain(state.types[type]).orderBy(['timestamp', 'asc']);

    state.types[type].splice(items[0], 1);
  },
  addViewed(state, { type, id }) {
    state.types[type].push({
      id,
      timestamp: new Date().getTime(),
    });
  },
  updateViewed(state, { type, id }) {
    const index = _.findIndex(state.types[type], { id });

    state.types[type].splice(index, 1, {
      id,
      timestamp: new Date().getTime(),
    });
  },
};

export default mutations;
