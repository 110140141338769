
import { mapGetters } from 'vuex';

export default {
  name: 'TheFooter',
  computed: {
    ...mapGetters({
      menus: 'settings/getMenus',
    }),
    leftMenu() {
      return this.menus?.footer_left_menu ?? null;
    },
    leftCenterMenu() {
      return this.menus?.footer_left_center_menu ?? null;
    },
    rightCenterMenu() {
      return this.menus?.footer_right_center_menu ?? null;
    },
    rightMenu() {
      return this.menus?.footer_right_menu ?? null;
    },
    verticalMenu() {
      return this.menus?.footer_vertical_menu?.items ?? [];
    },
    year() {
      return new Date().getFullYear();
    },
  },
};
