import { set } from "lodash";

export const state = () => ({
  currentStep: 1,
  outsideCancelPolicy:false,
})

export const mutations = {
  SET_CHECKOUT_STEP(state, step) {
    state.currentStep = step;
  },
  SET_OUTSIDE_CANCEL_POLICY(state, value){
    state.outsideCancelPolicy = value
  }
}

export const actions = {
  setCurrentStep({ commit }, step) {
    commit('SET_CHECKOUT_STEP', step);
  },
  setOutsideCancelPolicy({commit}, value){
    commit('SET_OUTSIDE_CANCEL_POLICY', value)
  }
}