import parseAcceptLanguage from '../lib/parse-accept-language';

export default async function ({ redirect, app, route, req }) {
  // If you're not on root or the its not running on the server, do nothing
  if (route.path !== '/' || !process.server) {
    return;
  }

  const headers = typeof req.headers !== 'undefined' ? req.headers : null;

  if (!headers) {
    return;
  }

  const acceptLanguage = typeof headers['accept-language'] !== 'undefined'
    ? headers['accept-language']
    : null;

  if (!acceptLanguage) {
    return;
  }

  try {
    // Parse accept language like "nl-NL,nl,en" into ['nl', 'nl', 'en']
    const parsed = parseAcceptLanguage(acceptLanguage);

    // Make sure the function returned something we can work with
    if (Array.isArray(parsed) && parsed.length) {
      // If you've 'nl' in your accept languages, stay on the current URL
      if (parsed.indexOf('nl') >= 0) {
        return;
      }

      // Check if the user has any of the other possible locales in its
      // accept language headers
      const matchedLocale = ['fr', 'en'].find((locale) => {
        return parsed.indexOf(locale) >= 0;
      });

      // If there's no locale matching the accept language (for example "pt-PT"),
      // redirect to the English site.
      if (!matchedLocale) {
        redirect('/en/');
        return;
      }

      // Redirect to the locale.
      redirect(`/${matchedLocale}/`);
      return;
    }
  } catch (err) {
    //
  }

  return;
}
