
export default {
  props: ['error'],
  layout: 'error', // you can set a custom layout for the error page
  computed: {
    status() {
      switch (this.error.statusCode) {
        case 403:
          return {
            code: 403,
            title: this.$t('errors.page-forbidden'),
            message: this.$t('errors.page-forbidden-message'),
          };
        case 404:
          return {
            code: 404,
            title: this.$t('errors.page-not-found'),
            message: this.$t('errors.page-not-found-message'),
          };
        default:
          return {
            code: 500,
            title: this.$t('errors.something-went-wrong'),
            message: this.$t('errors.something-went-wrong-message'),
          };
      }
    },
  },
};
