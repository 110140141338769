
export default {
  name: 'BModal',
  props: {
    isPage: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'modal-lg',
      validator: () => ['modal-lg', 'modal-md', 'modal-sm'],
    },
  },
  data() {
    return {
      modal: null,
    };
  },
  mounted() {
    this.modal = new this.$bootstrap.Modal(this.$refs.modal);
  },
  methods: {
    toggle() {
      this.modal.toggle();
    },
    show() {
      this.modal.show();
    },
    hide() {
      if (this.isPage) {
        this.$router.go(-1);
        return;
      }

      this.modal.hide();
    },
  },
  beforeDestroy() {
    this.hide();
  },
};
