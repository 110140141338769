import state from '../recently-viewed/state';
import getters from '../recently-viewed/getters';
import mutations from '../recently-viewed/mutations';
import actions from '../recently-viewed/actions';

const recentlyViewed = {
  state,
  getters,
  mutations,
  actions,
};

export default recentlyViewed;
