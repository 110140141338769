
import _ from 'lodash';

export default {
  name: 'language-switcher',
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: this.$i18n.locales,
    };
  },
  computed: {
    currentLocale() {
      let index = _.findIndex(this.locales, { code: this.$i18n.locale });

      if (!index) {
        index = _.findIndex(this.locales, { code: this.$i18n.defaultLocale });
      }

      return this.locales[index];
    },
    currentLocaleFlagCode() {
      return (
        this.currentLocale.flagCode ?? this.currentLocale.code ?? null
      );
    },
    restLocales() {
      return _.filter(
        this.locales,
        (locale) => locale.code !== this.currentLocale.code
      );
    },
  },
};
