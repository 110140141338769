
export default {
  name: 'AppPrice',
  props: {
    price: {
      default: 0,
      type: Number,
    },
    showDecimals: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    correctPrice() {
      let options = {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      if (!this.showDecimals) {
        options.minimumFractionDigits = 0;
        options.maximumFractionDigits = 0;
      }

      let value = new Intl.NumberFormat('nl', options).format(this.price);

      if (value.endsWith(',00')) {
        value = value.replace(',00', '');
      }

      return value;
    },
  },
};
