export default async function ({ redirect, app, route }) {
  const parts = route.fullPath.split('?');
  let url = parts[0];

  if (url.endsWith('/')) return;

  url += '/';

  if (!!parts[1]) {
    url += `?${parts[1]}`;
  }

  redirect(url);
}
