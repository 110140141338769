import { render, staticRenderFns } from "./SearchModalSidebar.vue?vue&type=template&id=48a0957c&"
import script from "./SearchModalSidebar.vue?vue&type=script&lang=js&"
export * from "./SearchModalSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchModalSidebarMaison: require('/home/forge/www.champagne-booking.com/components/SearchModalSidebarMaison.vue').default,SearchModalSidebarActivity: require('/home/forge/www.champagne-booking.com/components/SearchModalSidebarActivity.vue').default,SearchModalSidebarAccommodation: require('/home/forge/www.champagne-booking.com/components/SearchModalSidebarAccommodation.vue').default,SearchModalSidebarRoom: require('/home/forge/www.champagne-booking.com/components/SearchModalSidebarRoom.vue').default,SearchModalSidebarPage: require('/home/forge/www.champagne-booking.com/components/SearchModalSidebarPage.vue').default,SearchModalSidebarPost: require('/home/forge/www.champagne-booking.com/components/SearchModalSidebarPost.vue').default})
