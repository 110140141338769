import { render, staticRenderFns } from "./SearchModal.vue?vue&type=template&id=833155d2&scoped=true&"
import script from "./SearchModal.vue?vue&type=script&lang=js&"
export * from "./SearchModal.vue?vue&type=script&lang=js&"
import style0 from "./SearchModal.vue?vue&type=style&index=0&id=833155d2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "833155d2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchModalSidebar: require('/home/forge/www.champagne-booking.com/components/SearchModalSidebar.vue').default,SearchResults: require('/home/forge/www.champagne-booking.com/components/SearchResults.vue').default,AppDropdown: require('/home/forge/www.champagne-booking.com/components/AppDropdown.vue').default,BModal: require('/home/forge/www.champagne-booking.com/components/BModal.vue').default})
