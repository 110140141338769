const actions = {
  initialize({ commit }) {
    let favorites = this.$cookies.get('favorites');

    if (!favorites) {
      return;
    }

    commit('setTypes', JSON.parse(JSON.stringify(favorites)));
  },
  toggleFavorite({ commit, getters, state }, { type, id }) {
    let isFavored = getters.isFavorite(type, id);

    if (isFavored) {
      commit('removeFavorite', { type, id });
    } else {
      commit('addFavorite', { type, id });
    }

    this.$cookies.set('favorites', JSON.stringify(state.types));
  },
  removeFavorite({ state, commit }, { type, id }) {
    commit('removeFavorite', { type, id });

    this.$cookies.set('favorites', JSON.stringify(state.types));
  },
  async getFavorites({ state }) {
    let objects = [];

    for (let favorite_type in state.types) {
      let ids = state.types[favorite_type];

      if (!ids || ids.length < 1) {
        continue;
      }

      let query = new URLSearchParams();
      query.append('limit', 100);
      query.append('id', `in:${ids.join(',')}`);

      let { data } = await this.$api.$get(
        `api/v1/${favorite_type}?${query.toString()}`
      );

      objects.push(
        ...data.map((item) => ({
          ...item,
          favorite_type,
        }))
      );
    }

    return objects;
  },
};

export default actions;
