const middleware = {}

middleware['redirect-locale'] = require('../middleware/redirect-locale.js')
middleware['redirect-locale'] = middleware['redirect-locale'].default || middleware['redirect-locale']

middleware['trailing-slash'] = require('../middleware/trailing-slash.js')
middleware['trailing-slash'] = middleware['trailing-slash'].default || middleware['trailing-slash']

middleware['user-verification'] = require('../middleware/user-verification.js')
middleware['user-verification'] = middleware['user-verification'].default || middleware['user-verification']

export default middleware
