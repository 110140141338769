
export default {
  props: {
    results: {
      type: Object,
    },
  },
  methods: {
    onHover(object) {
      this.$emit('on-hover', object);
    },
  },
  computed: {
    hasMaisons() {
      return this.results.maisons && this.results.maisons.length > 0;
    },
    hasActivities() {
      return this.results.activities && this.results.activities.length > 0;
    },
    hasAccommodations() {
      return (
        this.results.accommodations && this.results.accommodations.length > 0
      );
    },
    hasRooms() {
      return this.results.rooms && this.results.rooms.length > 0;
    },
    hasPages() {
      return this.results.pages && this.results.pages.length > 0;
    },
    hasPosts() {
      return this.results.posts && this.results.posts.length > 0;
    },
  },
};
