import { render, staticRenderFns } from "./SearchResults.vue?vue&type=template&id=1cd749ca&"
import script from "./SearchResults.vue?vue&type=script&lang=js&"
export * from "./SearchResults.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchModalMaison: require('/home/forge/www.champagne-booking.com/components/SearchModalMaison.vue').default,SearchModalActivity: require('/home/forge/www.champagne-booking.com/components/SearchModalActivity.vue').default,SearchModalAccommodation: require('/home/forge/www.champagne-booking.com/components/SearchModalAccommodation.vue').default,SearchModalRoom: require('/home/forge/www.champagne-booking.com/components/SearchModalRoom.vue').default,SearchModalPage: require('/home/forge/www.champagne-booking.com/components/SearchModalPage.vue').default,SearchModalPost: require('/home/forge/www.champagne-booking.com/components/SearchModalPost.vue').default})
