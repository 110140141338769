
export default {
  name: 'TheLayout',
  props: {
    withTransparentHeader: {
      type: Boolean,
      default: false,
    },
  },
};
