import chat from './chat';
import settings from './settings';
import favorites from './favorites';
import recentlyViewed from './recently-viewed';
import dashboard from './dashboard';

const store = {
  actions: {
    async nuxtServerInit({ dispatch }) {
      await Promise.all([
        dispatch('settings/initialize'),
        dispatch('favorites/initialize'),
        dispatch('recently-viewed/initialize'),
      ]);
    },
  },

  modules: {
    chat,
    settings,
    favorites,
    recentlyViewed,
    dashboard,
  },
};

export default store;
