export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentTranslationWarn":true,"silentFallbackWarn":true},
  vueI18nLoader: true,
  locales: [{"switch":"Nederlands","lang":"Nederlands","code":"nl","file":"nl","flagCode":"nl"},{"switch":"English","lang":"English","code":"en","file":"en","flagCode":"gb"},{"switch":"Français","lang":"Français","code":"fr","file":"fr","flagCode":"fr"}],
  defaultLocale: "nl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: true,
  normalizedLocales: [{"switch":"Nederlands","lang":"Nederlands","code":"nl","file":"nl","flagCode":"nl"},{"switch":"English","lang":"English","code":"en","file":"en","flagCode":"gb"},{"switch":"Français","lang":"Français","code":"fr","file":"fr","flagCode":"fr"}],
  localeCodes: ["nl","en","fr"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "n",
  3: "l",
  4: "\"",
  5: ":",
  6: "\"",
  7: ".",
  8: ".",
  9: "/",
  10: "l",
  11: "a",
  12: "n",
  13: "g",
  14: "/",
  15: "n",
  16: "l",
  17: "\"",
  18: ",",
  19: "\"",
  20: "e",
  21: "n",
  22: "\"",
  23: ":",
  24: "\"",
  25: ".",
  26: ".",
  27: "/",
  28: "l",
  29: "a",
  30: "n",
  31: "g",
  32: "/",
  33: "e",
  34: "n",
  35: "\"",
  36: ",",
  37: "\"",
  38: "f",
  39: "r",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "a",
  48: "n",
  49: "g",
  50: "/",
  51: "f",
  52: "r",
  53: "\"",
  54: "}",
}

export const localeMessages = {
  'nl': () => import('../../lang/nl' /* webpackChunkName: "lang-nl" */),
  'en': () => import('../../lang/en' /* webpackChunkName: "lang-en" */),
  'fr': () => import('../../lang/fr' /* webpackChunkName: "lang-fr" */),
}
