
export default {
  name: 'SearchModalSidebarAccommodation',
  props: {
    accommodation: {
      type: Object,
    },
  },
  computed: {
    rooms() {
      return [...this.accommodation.rooms].splice(0, 4);
    },
  },
};
