export default function parseAcceptLanguage(header) {
  if (!header) {
    return [];
  }

  return header
    .split(',')
    .map(function(lang) {
      var parts = lang.trim().split(';');
      var language = parts[0];
      var weight = 1; // Default weight is 1 if not provided
      if (parts[1] && parts[1].indexOf('q=') === 0) {
        weight = parseFloat(parts[1].substr(2));
      }
      return { language: language, weight: weight };
    })
    .filter(function(lang) {
      return lang.language && lang.weight > 0;
    })
    .sort(function(a, b) {
      return b.weight - a.weight; // Sort by weight descending
    })
    .map(function(lang) {
      return lang.language.replace(/-\w{2}$/, '');
    });
}
