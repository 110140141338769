
export default {
  name: 'TheHeader',
  props: ['transparent', 'fixed'],
  data() {
    return {
      isOpen: false,
      timeout: null,
      collapse: null,
    };
  },
  computed: {
    logo() {
      return '/images/' + (this.fixed ? 'logo-white.webp' : 'logo.webp');
    },
    searchIcon() {
      return this.fixed ? 'header-search-white.svg' : 'header-search.svg';
    },
    champagneIcon() {
      return this.fixed ? 'header-champagne-white.svg' : 'header-champagne.svg';
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.isOpen = false;
      },
    },
  },
  mounted() {
    let collapse = document.getElementById('main-navbar');

    collapse.addEventListener('hide.bs.collapse', () => (this.isOpen = false));
    collapse.addEventListener('show.bs.collapse', () => (this.isOpen = true));

    this.$nextTick(function () {
      this.collapse = new this.$bootstrap.Collapse(collapse, {
        toggle: false,
      });
    });
  },
  methods: {
    hide() {
      this.collapse.hide();
    },
  },
  directives: {
    close: {
      bind(el, _, vnode) {
        el.closeMenu = (e) => {
          if (!document.getElementById('main-navbar').contains(e.target)) {
            vnode.context?.hide();
          }
        };

        document.addEventListener('click', el.closeMenu);
      },
      unbind(el) {
        document.removeEventListener('click', el.closeMenu);
      },
    },
  },
};
