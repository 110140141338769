
export default {
  name: 'SearchModalSidebarRoom',
  props: {
    room: {
      type: Object,
    },
  },
  computed: {
    thumbnail() {
      return this.room.thumbnail ?? '/images/placeholder.webp';
    },
  },
};
