export default async function (to, from, savedPosition) {
  const nuxt = window['$nuxt'];

  samePage: if (
    to.path === from.path &&
    JSON.stringify(to.params) === JSON.stringify(from.params)
  ) {
    if (
      from.query.hasOwnProperty('title') &&
      to.query.hasOwnProperty('title') &&
      from.query.title !== to.query.title
    ) {
      // break samePage;
    }

    return;
  }

  if (to.hash) {
    const elementToScrollTo = await document.querySelector(to.hash);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (elementToScrollTo) {
          return {
            x: 0,
            y: elementToScrollTo.getBoundingClientRect().top,
          };
        }
      }, 100);
    });
  }

  const position = savedPosition || { x: 0, y: 0 };

  return new Promise((resolve, reject) => {
    nuxt.$root.$once('triggerScroll', () => {
      nuxt.$nextTick(() => {
        resolve(position);
      });
    });
  });
}
