
export default {
  name: 'DashboardNavigation',
  props: {
    maisonPath: {
      type: String,
    },
  },
  computed: {
    items() {
      return [
        {
          label: 'general.dashboard',
          icon: 'fa-bars',
          path: {
            name: 'dashboard',
          },
        },
        {
          label: 'general.bookings',
          icon: 'fa-chart-line',
          path: {
            name: 'dashboard-bookings',
          },
        },
        {
          label: 'general.maisons-slots-link',
          icon: 'fa-building',
          path: this.maisonPath,
        },
        // {
        //   label: 'general.accommodations',
        //   icon: 'fa-hotel',
        //   path: {
        //     name: 'dashboard-accommodations',
        //   },
        // },
        {
          label: 'general.ratings',
          icon: 'fa-star',
          path: {
            name: 'dashboard-reviews',
          },
        },
        {
          label: 'general.chats-dashboard',
          icon: 'fa-message',
          path: {
            name: 'dashboard-chats',
          },
        },
      ];
    },
  },
};
