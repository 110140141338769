import { setClient } from '../lib/axios';

const https = require('https');

export default function ({ $axios, i18n, app }, inject) {
  const agent = new https.Agent({
    rejectUnauthorized: false,
  });

  const api = $axios.create({
    baseURL: process.env.API_URL,
    httpsAgent: agent,
  });

  api.interceptors.request.use((config) => {
    config.headers['x-accept-locale'] = i18n.locale;

    let token = app.$auth.strategy.token.get();
    if (!!token) {
      config.headers['Authorization'] = token;
    }

    return config;
  });

  setClient($axios);

  inject('api', api);
}
