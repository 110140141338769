
export default {
  name: 'TheFooterNav',
  props: [
    'title', 
    'titleClass',
    'horizontal',
    'navClass',
    'navListClass',
  ],
};
