import Vue from 'vue';
import {
  MglFullscreenControl,
  MglMap,
  MglMarker,
  MglNavigationControl,
  MglPopup,
} from 'vue-mapbox';

export default (_, inject) => {
  Vue.component('MglMap', MglMap);
  Vue.component('MglMarker', MglMarker);
  Vue.component('MglPopup', MglPopup);
  Vue.component('MglNavigationControl', MglNavigationControl);
  Vue.component('MglFullscreenControl', MglFullscreenControl);
};
