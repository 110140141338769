
export default {
  name: 'TheHeaderSubmenuItem',
  props: {
    to: {
      type: String,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: null | Function,
      default: null,
    },
  },
  methods: {
    clickHandler(e) {
      if (typeof this.onClick === 'function') {
        this.onClick(e);
      }
    },
  },
};
