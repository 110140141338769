
export default {
  name: 'SearchModalSidebarActivity',
  props: {
    activity: {
      type: Object,
    },
  },
  computed: {
    thumbnail() {
      return this.activity.thumbnail ?? '/images/placeholder.webp';
    },
  },
};
