
import layout from '@/mixins/layout';

export default {
  mixins: [layout],
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
};
